var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"related-products"}},[(_vm.mappedProducts && _vm.mappedProducts.length > 0)?_c('div',{staticClass:"title"},[_c('h2',{staticClass:"label_of_related_product"},[_vm._v("Related Products")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gallery"},[_c('div',{staticClass:"products"},_vm._l((_vm.mappedProducts),function(product,i){return _c('SfProductCard',{key:i,staticClass:"product",attrs:{"image-tag":"nuxt-img","title":_vm.productGetters.getName(product),"image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"image":_vm.getMagentoImage(_vm.productGetters.getProductThumbnailImage(product)),"nuxt-img-config":{
          fit: 'cover',
        },"regular-price":_vm.$fc(_vm.productGetters.getPrice(product).regular),"special-price":_vm.productGetters.getPrice(product).special &&
          _vm.$fc(_vm.productGetters.getPrice(product).special),"link":_vm.localePath(
            ("/p/" + (_vm.productGetters.getProductSku(
              product
            )) + (_vm.productGetters.getSlug(product, product.categories[0])))
          ),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"is-in-wishlist":_vm.isInWishlist({ product: product }),"is-added-to-cart":_vm.isInCart(product),"wishlist-icon":_vm.isAuthenticated ? 'heart' : '',"is-in-wishlist-icon":_vm.isAuthenticated ? 'heart_fill' : ''},on:{"click:wishlist":function($event){return _vm.addItemToWishlist(product)},"click:add-to-cart":function($event){return _vm.addItemToCart({ product: product, quantity: 1 })}}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="related-products">
    <div v-if="mappedProducts && mappedProducts.length > 0" class="title">
      <h2 class="label_of_related_product">Related Products</h2>
     </div>
     <div class="gallery">
      <div class="products">
        <SfProductCard
          v-for="(product, i) in mappedProducts"
          :key="i"
          class="product"
          image-tag="nuxt-img"
          :title="productGetters.getName(product)"
          :image-width="imageSizes.productCard.width"
          :image-height="imageSizes.productCard.height"
          :image="
            getMagentoImage(productGetters.getProductThumbnailImage(product))
          "
          :nuxt-img-config="{
            fit: 'cover',
          }"
          :regular-price="$fc(productGetters.getPrice(product).regular)"
          :special-price="
            productGetters.getPrice(product).special &&
            $fc(productGetters.getPrice(product).special)
          "
          :link="
            localePath(
              `/p/${productGetters.getProductSku(
                product
              )}${productGetters.getSlug(product, product.categories[0])}`
            )
          "
          :max-rating="5"
          :score-rating="productGetters.getAverageRating(product)"
          :reviews-count="productGetters.getTotalReviews(product)"
          :is-in-wishlist="isInWishlist({ product })"
          :is-added-to-cart="isInCart(product)"
          :wishlist-icon="isAuthenticated ? 'heart' : ''"
          :is-in-wishlist-icon="isAuthenticated ? 'heart_fill' : ''"
          @click:wishlist="addItemToWishlist(product)"
          @click:add-to-cart="addItemToCart({ product, quantity: 1 })"
        />
      </div>
      </div>

  </div>
</template>

<script>
import {
  SfButton,
  SfLoader,
  SfProductCard,
  SfSection,
} from "@storefront-ui/vue";

import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@nuxtjs/composition-api";
import { useImage, useProduct } from "~/composables";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import { useUser } from "~/modules/customer/composables/useUser";
import { useAddToCart } from "~/helpers/cart/addToCart";
import { SortEnum } from "~/modules/GraphQL/types";
export default {
    name:'RelatedProducts',
  components: {
    SfProductCard,
    SfSection,
    SfLoader,
    SfButton,
  },
  props:['category', 'brand', 'sku'],
  setup(props) {

      const { isAuthenticated } = useUser();
      const { getProductList, loading } = useProduct();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    const { addItemToCart, isInCart } = useAddToCart();
        const { getMagentoImage, imageSizes } = useImage();
    const products = ref([]);
    let a = props.sku;
    const mappedProducts = computed(() =>
      products.value.filter((product) => a !== product.sku)
    );
    onMounted(async ()=> {

        let sortFields = ['name', 'position', 'price', 'relevance'];
        let sortOrders = ['ASC', 'DESC'];

        const sortField = sortFields[Math.floor(Math.random() * sortFields.length)];
        const sortOrder = sortOrders[Math.floor(Math.random() * sortOrders.length)];
        const sortObj = {};
        sortObj[sortField] = sortOrder;

        let productFilter = {};

        if(props.brand === null) {
          productFilter = {
            category_uid: {in: props.category}
          }
        } else {
          productFilter = {
            category_uid: {in: props.category},
            brand: {eq: props.brand}
          }
        }

        const newestProducts = await getProductList({
        pageSize: 5,
        currentPage: 1,
        filter: productFilter,
        sort: sortObj

      });
      if (newestProducts?.items?.length) {
        products.value = newestProducts.items;
      }
    })


    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };


    return {
      products,
        addItemToCart,
      addItemToWishlist,
      isInCart,
      isInWishlist,
      loading,
      productGetters,
      getMagentoImage,
      imageSizes,
      isAuthenticated,
        mappedProducts
    };
  },
};
</script>

<style lang="scss" scoped>
#related-products .title{
  display: flex;
  justify-content: center;
  margin: 20px auto;
  margin-top: 100px;
  @media (max-width:1024px) {
    margin-top: 30px;
  }
}

 .products {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(20rem, 2fr)); */
  grid-template-columns: repeat(4, 1fr);
  // grid-gap: 1rem;
  // max-width: 80rem;
  // margin: 2rem auto;
  // padding: 0 5rem;
}
.products div:nth-child(5){
  display: none;
}
.products .product {
  width: 100%;
  height: fit-content;
  object-fit: cover;
  /* border-radius: 0.75rem; */
}

// @media only screen and (max-width:800px) {
//    .products{
//     grid-template-columns: repeat(3, 1fr);
//   }
//   .products div:nth-child(5),div:nth-child(6),div:nth-child(7),div:nth-child(8) {

//       display: none;

//   }
// }
@media only screen and (max-width:750px) {
   .products{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width:600px) {
   .products{
    grid-template-columns: repeat(2, 1fr);
  }
}

// @media only screen and (max-width:500px) {
//   /* For mobile phones: */
//   .products{
//     grid-template-columns: repeat(1, 1fr);
//   }
//   .products div:nth-child(2) {

//       display: none;

//   }
// }

.gallery {
    display: flex;
    justify-content: center;
}

.label_of_related_product{
  font-size:20pt;
}
</style>
